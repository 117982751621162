import React from "react";
import testimonies from "./testimonies.json";
import avatar1 from "../../assets/nodejs_cert.jpg";
import avatar2 from "../../assets/typescript_cert.jpg";

import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

const Testimonial = () => {
  const getAvatar = (id) => {
    switch (id) {
      case 1:
        return avatar1;
      case 2:
        return avatar2;

      default:
        return null;
    }
  };
  return (
    <Swiper
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      pagination={{ clickable: true }}
      className="pb-16"
    >
      {testimonies.map((testimony, index) => (
        <SwiperSlide
          className="bg-skin-bgVariant text-center p-8 rounded-[2rem] select-none"
          key={index}
        >
          <div className="w-16 aspect-square overflow-hidden mx-auto mt-0 mb-4 border-[0.4rem] border-skin-primaryVariant">
            <a href="../../assets/nodejs_cert.jpg">
              <img src={getAvatar(testimony.id)} alt={`${testimony.name}`} />
            </a>
          </div>
          <h5 className="client__name xxs:text-sm">{testimony.name}</h5>
          <p className="text-skin-primaryVariant pb-0">{testimony.From}</p>
          <small className="text-skin-light font-light block sm:w-4/5 mx-auto  mb-0 w-[90%]">
            {testimony.Instructors}
          </small>
          <i className="text-[0.5rem]">
            <a href={testimony.url} target="_blank" rel="noreferrer">
              {testimony.url}
            </a>
          </i>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Testimonial;
