import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { IoLogoTwitter } from "react-icons/io";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer
      id="footer"
      className="bg-skin-primary py-4 sm:py-12 px-0 text-center text-[0.9rem] mt-28"
    >
      <div className="footer__socials my-[2.6rem] flex justify-center gap-4 sm:mb-16">
        <a
          href="https://www.linkedin.com/in/precious-nnam/"
          target="_blank"
          rel="noreferrer"
          className="bg-skin-bg text-skin-white p-[0.8rem] rounded-[0.7rem] flex border-transparent hover:bg-transparent hover:text-skin-bg hover:border-skin-bg"
        >
          <BsLinkedin />
        </a>
        <a
          href="https://github.com/amakavanessa"
          target="_blank"
          rel="noreferrer"
          className="bg-skin-bg text-skin-white p-[0.8rem] rounded-[0.7rem] flex border-transparent hover:bg-transparent hover:text-skin-bg hover:border-skin-bg"
        >
          <FaGithub />
        </a>
        <a
          href="https://twitter.com/vanprechi"
          target="_blank"
          rel="noreferrer"
          className="bg-skin-bg text-skin-white p-[0.8rem] rounded-[0.7rem] flex border-transparent hover:bg-transparent hover:text-skin-bg hover:border-skin-bg"
        >
          <IoLogoTwitter />
        </a>
      </div>
      <div className="mb-16 text-skin-bg">
        <small>{currentYear} &copy; Precious Nnam. All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;
