import React from "react";

const Project = (props) => {
  return (
    <article className="p-[1.2rem] rounded-[2rem] border-transparent transitionEase hover:bg-skin-bgVariant  hover:border-skin-primaryVariant slideContainer">
      <div className="rounded-3xl overflow-hidden">{props.component}</div>
      <h3 className="mx-0 mt-[1.2rem] mb-8 transition-all duration-[450] ease-in title">
        {props.title}
      </h3>
      <div className="flex gap-4 mb-4">
        <a href={props.github} className="btn" target="_blank" rel="noreferrer">
          Github
        </a>
        <a
          href={props.project}
          className="btn btn-primary"
          target="_blank"
          rel="noreferrer"
        >
          Live Demo
        </a>
      </div>
    </article>
  );
};

export default Project;
