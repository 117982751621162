// import React from "react";

// const Projects = (props) => {
//   return (
//     <div
//       className="w-full h-[20rem] sm:h-[30rem] lg:h-[25rem] overflow-hidden relative"
//       style={{ perspective: "700px" }}
//     >
//       <div
//         className="grid grid-cols-3 gap-4 w-[60rem] sm:w-[80rem] lg:w-[50rem] h-[55rem] md:h-[90rem] lg:h-[55rem] overflow-hidden origin-[50%_0%] slide"
//         style={{
//           transform:
//             "translate3d(7%, -2%, 0px) scale3d(0.9, 0.8, 1) rotateX(15deg) rotateY(-9deg) rotateZ(32deg)",
//         }}
//       >
//         <div className="grid gap-9 w-full h-[440px] animate-slidingImgUp1">
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[1]}
//             alt={props.images[1]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[2]}
//             alt={props.images[2]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg "
//             src={props.images[3]}
//             alt={props.images[3]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[1]}
//             alt={props.images[1]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[2]}
//             alt={props.images[2]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg "
//             src={props.images[3]}
//             alt={props.images[3]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[1]}
//             alt={props.images[1]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[2]}
//             alt={props.images[2]}
//           />
//         </div>
//         <div className="grid gap-9 w-full h-[440px] animate-slidingImgdown">
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[5]}
//             alt={props.images[5]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[4]}
//             alt={props.images[4]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[6]}
//             alt={props.images[6]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[5]}
//             alt={props.images[5]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[4]}
//             alt={props.images[4]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[6]}
//             alt={props.images[6]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[5]}
//             alt={props.images[5]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[4]}
//             alt={props.images[4]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[6]}
//             alt={props.images[6]}
//           />
//         </div>
//         <div className="grid gap-9 w-full h-[440px] animate-slidingImgUp2">
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[8]}
//             alt={props.images[8]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[7]}
//             alt={props.images[7]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[9]}
//             alt={props.images[9]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[8]}
//             alt={props.images[8]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[7]}
//             alt={props.images[7]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[9]}
//             alt={props.images[9]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[8]}
//             alt={props.images[8]}
//           />
//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[7]}
//             alt={props.images[7]}
//           />

//           <img
//             className="w-full object-cover shadow-lg rounded-lg"
//             src={props.images[9]}
//             alt={props.images[9]}
//           />
//         </div>
//       </div>
//       <div className="transitionEase absolute bottom-0 left-0 right-0 bg-skin-bg overflow-y-auto w-full h-0 overlay ">
//         <h1 className="text-white text-lg p-6">{props.title}</h1>
//         <p className="py-4 px-6 overflow-y-auto text-[0.8rem]">
//           {props.projectDescription}
//         </p>
//         {props.tech && (
//           <div className="p-6">
//             <h2 className="text-white text-lg mb-4">Technologies/Frameworks</h2>

//             {/* Map over props.technologies */}
//             {props.tech.map((technology) => (
//               <span
//                 className="bg-skin-primaryVariant text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded  border border-blue-400"
//                 key={technology}
//               >
//                 {technology}
//               </span>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Projects;

import React from "react";
import VideoPlayer from "./Videoplayer";

const Projects = (props) => {
  return (
    <div className="w-full h-[20rem] sm:h-[30rem] lg:h-[25rem] overflow-hidden relative">
      <VideoPlayer videos={props.videos} />
      <div className="transitionEase absolute bottom-0 left-0 right-0 bg-skin-bg overflow-y-auto w-full h-0 overlay ">
        <h1 className="text-white text-lg p-6">{props.title}</h1>
        <p className="py-4 px-6 overflow-y-auto text-[0.8rem]">
          {props.projectDescription}
        </p>
        {props.tech && (
          <div className="p-6">
            <h2 className="text-white text-lg mb-4">Technologies/Frameworks</h2>
            {props.tech.map((technology) => (
              <span
                className="bg-skin-primaryVariant text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-blue-400"
                key={technology}
              >
                {technology}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
