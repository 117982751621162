import { BsPatchCheckFill } from "react-icons/bs";

const Skill = (props) => {
  return (
    <article className="flex gap-4 xxs:gap-2">
      <BsPatchCheckFill className="mt-[6px] text-skin-primary text-[0.7rem]" />{" "}
      <div>
        <h4 className="text-[0.7rem] py-2">{props.skill}</h4>
      </div>
    </article>
  );
};

export default Skill;
