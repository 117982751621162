import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
const HeaderSocials = () => {
  return (
    <div className="sm:flex flex-col items-center gap-3.5 absolute left-0 bottom-12 hidden">
      <a
        href="https://www.linkedin.com/in/precious-nnam/"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin />
      </a>
      <a
        href="https://github.com/amakavanessa/"
        target="_blank"
        rel="noreferrer"
      >
        <FaGithub />
      </a>
      <span className="w-[1px] h-8 bg-skin-primary"></span>
    </div>
  );
};

export default HeaderSocials;
