import React from "react";
import CV from "../../assets/precious_nnam.pdf";
const CTA = () => {
  return (
    <div className="mt-10 flex gap-4 justify-center">
      <a href={CV} download className="btn">
        Download CV
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's talk
      </a>
    </div>
  );
};

export default CTA;
