import CTA from "./CTA";
import ME from "../../assets/me.png";
import HeaderSocials from "./HeaderSocials";

const Header = () => {
  return (
    <header
      id="header"
      className="h-screen long:h-[90vh] xlong:h-[80vh] 2xlong:h-[68vh] 3xlong:h-[58vh] pt-28 xshort:pt-14 overflow-hidden"
    >
      <div className="container text-center h-full relative  lg:h-full ">
        <div>
          <h5 className="xxs:text-[0.8rem] font-thin">Hello I'm</h5>
          <h1 className="text-[1.8rem] sm:text-[2.5rem] xxs:text-[1.6rem] ">
            Precious Nnam
          </h1>
          <h5 className="font-light text-sm xxs:text-[0.6rem]">
            Fullstack Web Developer
          </h5>
        </div>
        <CTA />
        <HeaderSocials />
        <div className="bg-gradient-to-b from-skin-primary to-transparent w-[22rem] xshort:w-[15rem] short:w-[19rem] h-[30rem] absolute left-1/2 translate-x-[-50%] mt-16 xshort:mt-12 rounded-t-full overflow-hidden pt-20 pl-6 pr-6 pb-6 !header-image">
          <img src={ME} alt="" />
        </div>

        <a
          href="#contact"
          className="absolute -right-[2.3rem] bottom-20 rotate-90 font-light text-[0.9rem] hidden sm:inline"
        >
          Scroll down{" "}
        </a>
      </div>
    </header>
  );
};

export default Header;
