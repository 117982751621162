import { React, useRef } from "react";
import emailjs from "emailjs-com";

import { MdOutlineEmail } from "react-icons/md";
import ContactOption from "./ContactOption";
import scanWhatsapp from "../../assets/frame.png";
const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_uee1vgd",
        "template_v78wilr",
        form.current,
        "vsUCotpPEzpQrnlnU"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <section id="contact" className="mt-24 lg:mt-32">
      <h5>Get In Touch</h5>

      <h2 className="mb-8 sm:mb-12">Contact Me</h2>
      <div className="container grid grid-cols-1 gap-8 w-[90%] md:w-4/5 lg:unequal-grid">
        <div className="flex flex-col gap-[1.2rem] ">
          <ContactOption
            option="Email"
            link="mailto:precious.c.nnam@gmail.com"
            icon={MdOutlineEmail}
          />

          <ContactOption option="Message Me On Whatsapp" image={scanWhatsapp} />
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form
          ref={form}
          onSubmit={sendEmail}
          className="flex flex-col gap-[1.2rem]"
        >
          <input
            type="text"
            name="name"
            placeholder="Your full name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your message"
            required
          ></textarea>
          <button className="btn btn-primary">Send Your message</button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
