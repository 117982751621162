import React from "react";

const VideoPlayer = ({ videos }) => {
  return (
    <div className="video-container">
      <video
        src={videos}
        autoPlay
        loop
        muted
        className="w-full h-[350px] rounded-lg"
      />
    </div>
  );
};

export default VideoPlayer;
