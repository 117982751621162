import React, { useState } from "react";

import ME from "../../assets/me_about.png";
import { FiAward } from "react-icons/fi";
import { LuGraduationCap } from "react-icons/lu";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const About = () => {
  const [activeTab, setActiveTab] = useState("XpTab");
  const [expandedItem, setExpandedItem] = useState(null); // Only one item expanded

  const handleHover = (tab) => {
    setActiveTab(tab);
  };

  const toggleItem = (index) => {
    setExpandedItem((prevIndex) => (prevIndex === index ? null : index));
  };

  const experienceData = [
    {
      title: "Software Developer",
      company: "Mquid Integrated Solution",
      duration: "2024 - ",
      description: [
        "Developed a custom Chart of Accounts platform, replacing expensive third-party software and achieving $30,000 in annual savings while enhancing support and customization capabilities.",
        "Implemented a BVN/NIN caching system, reducing verification costs by 75% and streamlining loan approvals by efficiently reusing cached data for returning customers.",
        "Developed a business intelligence dashboard to track key metrics, improving decision-making and saving $25,000 annually by replacing a third-party tool.",
      ],
    },
    {
      title: "Junior Developer",
      company: "Andyke Software",
      duration: "2022 - 2024",
      description: [
        "Developed a file-sharing website for a X(twitter) bot with 1000+ daily users, optimized for ad integration, contributing 10% to the company's cash inflow.",
        "Maintained a robust authentication system for a dispatch hailing application with 15,000+ active users, enhancing security and reducing unauthorized access.",
      ],
    },
    {
      title: "Volunteer Software Developer Instructor/Coach",
      company: "Learnhub Africa",
      duration: "2022 - 2023",
      description: [
        "Taught web development courses to students of all levels, from beginners to experienced developers.",
        "Developed and delivered engaging and effective training materials that cover a variety of topics, including HTML, CSS, JavaScript, and React.",
        "Offered individual feedback and guidance to trainees, helping them to understand coding concepts and troubleshoot issues with their code.",
      ],
    },
    {
      title: "Web developer Intern",
      company: "Digital Dreams",
      duration: "June 2019 - Dec 2019",
      description: [
        "Designed and developed a secure local chat system to facilitate communication among users within the same network, ensuring the privacy and reliability of messaging.",
        "Collaborated closely with industry professionals to gain in-depth insights into various development paradigms and acquire proficiency in comprehensive software analysis, programming, testing, and debugging.",
      ],
    },
  ];

  const educationData = [
    {
      degree: "Bachelor of Engineering in Electronic and Computer Engineering",
      institution: "University of Nigeria Nsukka",
      description: ["Data structures and algorithms", "System Design"],
    },
    {
      degree: "Diploma in Fullstack Software Development",
      institution: "Digital Dreams ICT Academy",
    },
  ];

  return (
    <section id="about" className="mt-24 lg:mt-32">
      <h5>Get to know me</h5>
      <h2 className="mb-8 sm:mb-12 lg:mb-0">About me</h2>
      <div className="container grid-cols-1 gap-0 lg:about__container mt-16">
        <div className="lg:w-full w-[65%] aspect-square rounded-[2rem] bg-gradient-to-tr from-transparent via-skin-primary to-transparent grid place-items-center md:w-1/2 md:mt-8 md:mx-auto md:mb-16 mt-0 mx-auto mb-12">
          <div className="rounded-[2rem] overflow-hidden rotate-12 transitionEase hover:rotate-0">
            <img src={ME} alt="About myself" />
          </div>
        </div>
        <div className="text-center mt-16 md:mt-0">
          <div className="grid md:grid-cols-2 md:gap-6 grid-cols-2 gap-4 aboutList">
            <div
              className={activeTab === "XpTab" ? "active" : ""}
              onMouseEnter={() => handleHover("XpTab")}
            >
              <article className="bg-transparent border-1 border-transparent rounded-2xl p-8 xxs:py-6 transitionEase hover:bg-transparent hover:border-skin-primaryVariant cursor-default">
                <div className="flex flex-col items-center justify-center text-center">
                  <div className="flex justify-center">
                    <FiAward className="text-skin-primary text-[1.4rem] xxs:text-[1rem] mb-4" />
                  </div>
                  <h5 className="text-[0.95rem] xxs:text-[0.8rem]">
                    Experience
                  </h5>
                </div>
              </article>
            </div>

            <div
              className={activeTab === "EdTab" ? "active" : ""}
              onMouseEnter={() => handleHover("EdTab")}
            >
              <article className="bg-transparent border-1 border-transparent rounded-2xl p-8 xxs:py-6 text-center transitionEase hover:bg-transparent hover:border-skin-primaryVariant cursor-default">
                <div className="flex flex-col items-center justify-center text-center">
                  <div className="flex justify-center">
                    <LuGraduationCap className="text-skin-primary text-[1.4rem]  xxs:text-[1rem] mb-4" />
                  </div>
                  <h5 className="text-[0.95rem] xxs:text-[0.8rem]">
                    Education
                  </h5>
                </div>
              </article>
            </div>
          </div>

          {/* summary */}
          {activeTab === "XpTab" ? (
            <div className="lg:mt-8 mx-0 lg:mb-11 md:mt-4 md:mb-6 my-6 text-[0.9rem]">
              <h3 className="text-end">4+ years of experience</h3>
              <ul className="aboutList text-start p-8 pb-0 xxs:px-2">
                {experienceData.map((item, index) => (
                  <li key={index}>
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() => toggleItem(index)}
                    >
                      {" "}
                      <div className="flex flex-col">
                        <p className="text-skin-white">{item.title}</p>

                        <small className="text-skin-light">
                          {item.company}, <i>{item.duration}</i>
                        </small>
                      </div>
                      {expandedItem === index ? (
                        <FiChevronUp className="text-skin-light" />
                      ) : (
                        <FiChevronDown className="text-skin-light" />
                      )}
                    </div>
                    {expandedItem === index && (
                      <div>
                        {/* Display description */}
                        <ul className="mt-2 text-skin-light list-disc list-inside">
                          {item.description.map((desc, i) => (
                            <li key={i}>{desc}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="lg:mt-8 mx-0 lg:mb-11 md:mt-4 md:mb-6 my-6 text-[0.9rem]">
              <ul className="aboutList text-start p-8">
                {educationData.map((item, index) => (
                  <li key={index}>
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() => toggleItem(index)}
                    >
                      {" "}
                      <div className="flex flex-col">
                        <p className="text-skin-white">{item.degree}</p>{" "}
                        <small className="text-skin-light">
                          {item.institution}
                        </small>
                      </div>
                      {expandedItem === index ? (
                        <FiChevronUp className="text-skin-light" />
                      ) : (
                        <FiChevronDown className="text-skin-light" />
                      )}
                    </div>
                    {expandedItem === index && (
                      <div>
                        {" "}
                        <ul className="mt-2 text-skin-light list-disc list-inside">
                          {item.description.map((desc, i) => (
                            <li key={i}>{desc}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <a href="#contact" className="btn btn-primary">
            Let's talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
