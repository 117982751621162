import React from "react";

import Testimonial from "./Testimonial";

const Testimonials = () => {
  return (
    <section id="testimonials" className="mt-24 lg:mt-32">
      <h5 className="capitalize">I embrace continous learning</h5>
      <h2 className="mb-8 sm:mb-12">Certifications</h2>

      <div className="container w-[90%] lg:w-2/5 sm:w-3/5">
        <Testimonial />
      </div>
    </section>
  );
};

export default Testimonials;
