import React from "react";

const ContactOption = (props) => {
  const IconComponent = props.icon;
  return (
    <article className="bg-skin-bgVariant p-[1.2rem] rounded-[1.2rem] text-center border-transparent transitionEase hover:bg-transparent hover:border-skin-primaryVariant">
      {IconComponent && (
        <div className="flex justify-center">
          <IconComponent className="text-2xl mb-2" />
        </div>
      )}
      {props.option && <h4>{props.option}</h4>}
      {props.username && <h5>{props.username}</h5>}
      {props.link && (
        <a
          href={props.link}
          target="_blank"
          rel="noreferrer"
          className="mt-[0.7] inline-block text-[0.8rem]"
        >
          Send a message
        </a>
      )}
      {props.image && (
        <div className="flex justify-center items-center m-4">
          <img
            src={props.image}
            alt="Scan to connect on whatsapp"
            className="w-48 h-48"
          />
        </div>
      )}
    </article>
  );
};

export default ContactOption;
