import React from "react";

import Skill from "./Skills";
const Experience = () => {
  return (
    <section id="experience" className="mt-24 lg:mt-32">
      <h5>What Skills I Have</h5>
      <h2 className="mb-8 sm:mb-12"> Languages and Tools</h2>
      <div className="container grid grid-cols-1 gap-4 md:gap-8 lg:grid-cols-3">
        <div className="bg-skin-bgVariant p-8 xxs:px-2 xxs:pb-0 my-0 mx-auto lg:py-[2.4rem]  w-full lg:px-10 border border-solid border-transparent rounded-[2rem] transitionEase hover:bg-transparent hover:border-skin-primaryVariant hover:cursor-default">
          <h3 className="text-center mb-8 text-skin-primary">
            Frontend Development
          </h3>

          <div className="grid gap-2 grid-cols-2 grid-rows-4 p-4 lg:p-0">
            <Skill skill="CSS" />
            <Skill skill="HTML" />
            <Skill skill="TypeScript" />
            <Skill skill="JavaScript" />
            <Skill skill="ReactJs" />
            <Skill skill="ReduxJs" />
            <Skill skill="VueJs" />
            <Skill skill="NuxtJs" />
          </div>
        </div>
        {/* END OF FRONT END */}
        <div className="bg-skin-bgVariant p-8 xxs:px-2 xxs:pb-0  my-0 mx-auto lg:py-[2.4rem] w-full lg:px-10 border border-solid border-transparent rounded-[2rem] transitionEase hover:bg-transparent hover:border-skin-primaryVariant hover:cursor-default">
          <h3 className="text-center mb-8 text-skin-primary">
            Backend Development
          </h3>

          <div className="grid gap-2 grid-cols-2 grid-rows-4 p-4 lg:p-0">
            <Skill skill="NodeJs" />
            <Skill skill="ExpressJS" />
            <Skill skill="NestJS" />
            <Skill skill="Laravel" />
            <Skill skill="Mongo DB" />
            <Skill skill="PHP" />
            <Skill skill="MySQL DB" />
            <Skill skill="PostgreSQL" />
            <Skill skill="TypeORM" />
          </div>
        </div>
        {/**END OF BACK END */}

        <div className="bg-skin-bgVariant p-8 xxs:px-2 xxs:pb-0 my-0 mx-auto lg:py-[2.4rem]  w-full lg:px-10 border border-solid border-transparent rounded-[2rem] transitionEase hover:bg-transparent hover:border-skin-primaryVariant hover:cursor-default">
          <h3 className="text-center mb-8 text-skin-primary">Tools</h3>

          <div className="grid gap-2 grid-cols-2 grid-rows-4 p-4 lg:p-0">
            <Skill skill="Firebase" />
            <Skill skill="Wordpress" />

            <Skill skill="Figma" />
            <Skill skill="Cloudflare" />
            <Skill skill="Jest" />
            <Skill skill="Github" />
            <Skill skill="Webpack" />
            <Skill skill="Docker" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
