import React from "react";
import Project from "./Project";
import Projects from "./Projects";
import royalApparel from "../../assets/RoyalApparel.mp4";
import livedraft from "../../assets/LiveDraft.mp4";
import natours from "../../assets/Natours.mp4";
import taskmanager from "../../assets/taskmanager.mp4";
import tier2 from "../../assets/Tier2.mp4";
import portfolio from "../../assets/Portfolio.mp4";
import figma2design from "../../assets/figma2Design.mp4";
const Portfolio = () => {
  return (
    <section id="portfolio" className="mt-24 lg:mt-32">
      <h5>Some Personal Projects</h5>
      <h2 className="mb-8 sm:mb-12">Portfolio</h2>
      <div className="container grid grid-cols-1 gap-4 md:gap-[1.2rem] lg:grid-cols-2 lg:gap-4">
        <Project
          component={
            <Projects
              videos={portfolio} // Use video URLs
              title={"My Portfolio Website"}
              projectDescription={
                "A website to showcase my professional accomplishments, skills and projects."
              }
              tech={["ReactJS", "TailwindCSS", "Scss", "EmailJS", "SwiperJS"]}
            />
          }
          title={"My Portfolio Website"}
          github={"https://github.com/amakavanessa/Portfolio-site"}
          project={"https://preciousnnam.com"}
        />
        <Project
          component={
            <Projects
              videos={livedraft} // Use video URLs
              title={"Livedraft Realtime collaboration tool"}
              projectDescription={
                "A collaborative online document editor with real-time multi-user editing, live updates, version control, and rich text formatting"
              }
              tech={[
                "React",
                "Nodejs",
                "Express",
                "Typescript",
                "Draftjs",
                "Websocket",
                "Postgresql",
              ]}
            />
          }
          title={"Livedraft Realtime collaboration tool"}
          github={
            "https://github.com/amakavanessa/real-time-collaboration-tool/"
          }
          project={"https://livedraft.netlify.app/"}
        />
        <Project
          component={
            <Projects
              videos={royalApparel}
              title={"Royal Inc Ecommerce Web application"}
              projectDescription={
                "The E-commerce Clothing Store Application is an online clothing store where users can browse, select, and purchase apparels. The application incorporates features such as secure authentication using Firebase Authentication and user data storage using the Firebase database. Additionally, Redux.js is utilized for managing the application's states, enhancing the overall functionality and user experience"
              }
              tech={[
                "React",
                "Redux",
                "Firebase",
                "Paystack",
                "Scss",
                "Swiper.js",
                "JSX styled components",
              ]}
            />
          }
          title={"Royal Inc Ecommerce Web application"}
          github={"https://github.com/amakavanessa/E-commerce-Project"}
          project={"https://royal-inc.netlify.app/"}
        />
        <Project
          component={
            <Projects
              videos={figma2design} // Use video URLs
              title={"Figma to Website Conversion with API Integration"}
              projectDescription={
                "A website showcasing my ability to convert Figma designs into responsive, pixel-perfect websites while integrating APIs for dynamic content and real-time updates."
              }
              tech={[
                "ReactJS",
                "Typescript",
                "CSS",
                "Figma",
                "highcharts",
                "API integration",
              ]}
            />
          }
          title={"Figma to Website Conversion with API Integration"}
          github={"https://github.com/amakavanessa/frontend-task"}
          project={"https://codeapicanvas.netlify.app/"}
        />
        <Project
          component={
            <Projects
              videos={natours}
              title={"TourX Tour Finding Application"}
              projectDescription={
                "The application provides users with the ability to search and book tours, securely authenticate their accounts, view tour locations on maps, and make payments through the Paystack gateway."
              }
              tech={[
                "NodeJS",
                "ExpressJs",
                "PUG",
                "Paystack",
                "MongoDB",
                "Mapbox",
              ]}
            />
          }
          title={"TourX Tour Finding Application"}
          github={"https://github.com/amakavanessa/Natours-Project"}
          project={"http://natours-1j5v.onrender.com/"}
        />{" "}
        <Project
          component={
            <Projects
              videos={taskmanager} // Use video URLs
              title={"Typescript Project Manager"}
              projectDescription={
                "The TypeScript-based Project Manager is a Trello-inspired application with drag and drop functionality. It effectively organizes projects, sorting them into two categories: 'completed' and 'active'. However, to enhance this project further, implementing  user storage will be essential."
              }
              tech={["HTML", "Typescript", "CSS", "Webpack", "Drag and Drop"]}
            />
          }
          title={"Typescript Project Manager"}
          github={"https://github.com/amakavanessa/Project-manager"}
          project={"https://tsprojectmanager.netlify.app/"}
        />
        <Project
          component={
            <Projects
              videos={tier2} // Use video URLs
              title={"Uk Tier2 Sponsorship Finder Platform"}
              projectDescription={
                "The UK Tier 2 Sponsorship Finder Platform is a project aimed at providing a comprehensive solution for immigrants in the UK to find employers who are willing to sponsor their Tier 2 visas. The platform assists users in searching for job sponsors based on various criteria such as keywords, route, town, county, and organization name."
              }
              tech={[
                "Python",
                "TailwindCSS",
                "ReactJS",
                "react-data table",
                "react-joyride",
              ]}
            />
          }
          title={"Uk Tier2 Sponsorship Finder Platform"}
          github={
            "https://github.com/Chuukwudi/uk-sponsorship/tree/react-front-end"
          }
          project={"https://uksponsorship.info/"}
        />
      </div>
    </section>
  );
};

export default Portfolio;
