import React, { useState, useEffect } from "react";

import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
import { MdOutlineWidgets } from "react-icons/md";
import { BiMessageSquareDetail } from "react-icons/bi";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the offset positions for each section
      const headerOffset = document.getElementById("header").offsetTop;
      const aboutOffset = document.getElementById("about").offsetTop;
      const experienceOffset = document.getElementById("experience").offsetTop;
      const portfolioOffset = document.getElementById("portfolio").offsetTop;
      const contactOffset = document.getElementById("contact").offsetTop;

      const scrollPosition = window.scrollY;

      if (scrollPosition >= headerOffset && scrollPosition < aboutOffset) {
        setActiveNav("#");
      } else if (
        scrollPosition >= aboutOffset &&
        scrollPosition < experienceOffset
      ) {
        setActiveNav("#about");
      } else if (
        scrollPosition >= experienceOffset &&
        scrollPosition < portfolioOffset
      ) {
        setActiveNav("#experience");
      } else if (
        scrollPosition >= portfolioOffset + 250 &&
        scrollPosition < contactOffset
      ) {
        setActiveNav("#portfolio");
      } else if (scrollPosition >= contactOffset) {
        setActiveNav("#contact");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className="bg-black bg-opacity-30 w-max py-3 px-7 xxs:py-1.5 xxs:px-3.5 z-[2] fixed left-1/2 translate-x-[-50%] bottom-8 flex gap-3.5 xxs:gap-0.5 rounded-[3rem] backdrop-blur-lg">
      <a
        href="#header"
        onClick={() => setActiveNav("#")}
        className={activeNav === "#" ? "active" : ""}
      >
        <AiOutlineHome />
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={activeNav === "#about" ? "active" : ""}
      >
        <AiOutlineUser />
      </a>
      <a
        href="#experience"
        onClick={() => setActiveNav("#experience")}
        className={activeNav === "#experience" ? "active" : ""}
      >
        <BiBook />
      </a>
      <a
        href="#portfolio"
        onClick={() => setActiveNav("#portfolio")}
        className={activeNav === "#portfolio" ? "active" : ""}
      >
        <MdOutlineWidgets />
      </a>
      <a
        href="#contact"
        onClick={() => setActiveNav("#contact")}
        className={activeNav === "#contact" ? "active" : ""}
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
};

export default Nav;
